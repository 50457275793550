








































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { readAdminCategories } from '@/store/admin/getters';
import { dispatchGetCategories, dispatchDeleteCategory } from '@/store/admin/actions';

@Component
export default class AdminCategories extends Vue {
  public search = '';
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'display_name',
      align: 'left',
    },
    {
      text: 'Actions',
      sortable: false,
      align: 'center',
    },
  ];
  get categories() {
    return readAdminCategories(this.$store);
  }

  public async deleteCategory(categoryID) {
    await dispatchDeleteCategory(this.$store, categoryID);
  }

  public async mounted() {
    await dispatchGetCategories(this.$store);
  }
}
