

































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readAdminNews } from '@/store/admin/getters';
import {
  INews,
} from '@/interfaces';
import { dispatchGetNews, dispatchSetNews } from '@/store/admin/actions';

@Component
export default class AdminNews extends Vue {
  // News are limited to three items and can be edited all at once.
  // This is why the three news items are hardcoded instead of being a list.
  public valid: boolean = false;
  public title1: string = '';
  public content1: string = '';
  public title2: string = '';
  public content2: string = '';
  public title3: string = '';
  public content3: string = '';

  public async mounted() {
    this.reset();
  }
  public async reset() {
    await dispatchGetNews(this.$store);
    const news = readAdminNews(this.$store);
    if (news.length > 0) {
      this.title1 = news[0].title;
      this.content1 = news[0].content;
    }
    if (news.length > 1) {
      this.title2 = news[1].title;
      this.content2 = news[1].content;
    }
    if (news.length > 2) {
      this.title3 = news[2].title;
      this.content3 = news[2].content;
    }
  }
  public async submit() {
    if (await this.$validator.validateAll()) {
      const news: INews[] = [];
      if (this.title1) {
        news.push({
          title: this.title1,
          content: this.content1,
        });
      }
      if (this.title2) {
        news.push({
          title: this.title2,
          content: this.content2,
        });
      }
      if (this.title3) {
        news.push({
          title: this.title3,
          content: this.content3,
        });
      }
      await dispatchSetNews(this.$store, news);
    }
  }
}
